
import { defineComponent, ref, reactive, toRefs, onMounted } from "vue";
import Navbar from "@/components/Navbar.vue";
import { List, Notify, Empty } from "vant";
import { getCateList, getModelList } from "@/api/service";

interface stateFace {
  // 案例列表
  caseList: Array<any>;
  loading: boolean;
  finished: boolean;
  page: number;
  totalPage: number;
  category: string | number;
  isConcat: boolean;
}
export default defineComponent({
  components: {
    Navbar,
    [List.name]: List,
    [Empty.name]: Empty,
  },
  setup(props, ctx) {
    // 静态数据
    const search = true;

    /**
     * 分类模块
     */
    // 选中的下标
    var checkedIndex = ref<number>(0);
    // 分类列表
    let cateList = ref([]);
    // 获取ul标签dom
    const cateUlRef = ref<HTMLUListElement | null>(null);
    // 获取li标签dom
    const cateRef = ref();
    // li标签滑动距离
    const transWidth = ref(0);

    onMounted(() => {
      getCate();
    });

    // 获取分类列表
    const getCate = async () => {
      const res = await getCateList();
      if (
        res.data.code == 200 &&
        res.data.result &&
        res.data.result.length > 0
      ) {
        cateList.value = res.data.result;
        state.category = res.data.result[0].id;
        // 获取模型列表
        getModel();
      } else {
        cateList.value = [];
        Notify({
          type: "warning",
          message: "分类数据为空！",
          duration: 1000,
        });
      }
    };

    const cateRefList = (el: any) => {
      cateRef.value.push(el);
    };

    // 分类选中
    const cateCheck = (index: number, val: string) => {
      state.category = val;
      state.page = 1;
      state.isConcat = false;
      state.caseList = [];
      state.finished = false;
      checkedIndex.value = index;
      // nextTick(() => {
      //   console.log(cateUlRef.value?.offsetLeft);
      //   console.log(cateRef.value);
      //   // transWidth.value =
      //   //   (cateRef.value ? cateRef.value.offsetLeft : 0) -
      //   //   (cateUlRef.value ? cateUlRef.value.offsetLeft : 0) -
      //   //   60;
      //   // console.log(transWidth.value);
      // });
      getModel();
    };

    var isThtottle = ref(true);
    // 分类滑动事件监听
    const touchmove = () => {
      ctx.emit("touchBgmShow", true);
      if (!isThtottle.value) return;
      isThtottle.value = false;
      setTimeout(() => {
        ctx.emit("touchBgmShow", false);
        isThtottle.value = true;
      }, 800);
    };

    /**
     * 案例模型模块
     */

    const state: stateFace = reactive({
      // 案例列表
      caseList: [],
      // 加载状态
      loading: false,
      // 没有更多了
      finished: false,
      page: 1,
      totalPage: 0,
      category: 0,
      // 是否拼接模型列表
      isConcat: false,
    });

    // 获取模型列表
    const getModel = async () => {
      let condition = {
        rows: 10,
        page: state.page,
        category: state.category,
      };
      const res = await getModelList(condition);

      if (
        res.data.code == 200 &&
        res.data.result.list &&
        res.data.result.list.length > 0
      ) {
        if (state.isConcat) {
          state.caseList = state.caseList.concat(res.data.result.list);
        } else {
          state.caseList = res.data.result.list;
        }
        // 总页数
        state.totalPage = Math.ceil(res.data.result.total / 10);
        // 加载状态结束
        state.loading = false;
        // 页数大于等于总页数则加载完成
        if (state.page >= state.totalPage) {
          state.finished = true;
        }
      } else {
        state.caseList = [];
        if (state.page >= state.totalPage) {
          state.finished = true;
        }
      }
    };

    // 上拉加载更多
    const onLoad = () => {
      // 异步更新数据
      state.page++;
      state.isConcat = true;
      getModel();
    };

    // 前往案例页
    const toCase = (val: string) => {
      window.open(val);
    };

    return {
      search,
      cateList,
      cateRefList,
      cateUlRef,
      transWidth,
      ...toRefs(state),
      checkedIndex,
      cateCheck,
      onLoad,
      touchmove,
      toCase,
    };
  },
});
