<template>
  <div class="case">
    <Navbar :search="search" />
    <ul class="case_tabs" ref="cateUlRef" @touchmove="touchmove">
      <li
        :class="{ active: checkedIndex == index }"
        v-for="(item, index) in cateList"
        :key="index"
        ref="cateRefList"
        @click="cateCheck(index, item.id)"
      >
        {{ item.name }}
      </li>
    </ul>
    <div class="case_content">
      <van-list
        v-show="caseList.length > 0"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        error-text="网络好像开小差了"
        :immediate-check="false"
        @load="onLoad"
      >
        <div
          class="case_content_item"
          v-for="(item, index) in caseList"
          :key="index"
          @click="toCase(item.link)"
        >
          <img :src="'//manage.513dvr.com' + item.thumb" />
          <p
            class="display_flex align-items_center justify-content_flex-justify"
          >
            <span>{{ item.title }}</span>
            <span>进入<van-icon name="arrow" /></span>
          </p>
        </div>
      </van-list>
    </div>
    <van-empty
      v-show="!caseList.length > 0"
      image="//img.yzcdn.cn/vant/custom-empty-image.png"
      description="该分类暂无案例"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, toRefs, onMounted } from "vue";
import Navbar from "@/components/Navbar.vue";
import { List, Notify, Empty } from "vant";
import { getCateList, getModelList } from "@/api/service";

interface stateFace {
  // 案例列表
  caseList: Array<any>;
  loading: boolean;
  finished: boolean;
  page: number;
  totalPage: number;
  category: string | number;
  isConcat: boolean;
}
export default defineComponent({
  components: {
    Navbar,
    [List.name]: List,
    [Empty.name]: Empty,
  },
  setup(props, ctx) {
    // 静态数据
    const search = true;

    /**
     * 分类模块
     */
    // 选中的下标
    var checkedIndex = ref<number>(0);
    // 分类列表
    let cateList = ref([]);
    // 获取ul标签dom
    const cateUlRef = ref<HTMLUListElement | null>(null);
    // 获取li标签dom
    const cateRef = ref();
    // li标签滑动距离
    const transWidth = ref(0);

    onMounted(() => {
      getCate();
    });

    // 获取分类列表
    const getCate = async () => {
      const res = await getCateList();
      if (
        res.data.code == 200 &&
        res.data.result &&
        res.data.result.length > 0
      ) {
        cateList.value = res.data.result;
        state.category = res.data.result[0].id;
        // 获取模型列表
        getModel();
      } else {
        cateList.value = [];
        Notify({
          type: "warning",
          message: "分类数据为空！",
          duration: 1000,
        });
      }
    };

    const cateRefList = (el: any) => {
      cateRef.value.push(el);
    };

    // 分类选中
    const cateCheck = (index: number, val: string) => {
      state.category = val;
      state.page = 1;
      state.isConcat = false;
      state.caseList = [];
      state.finished = false;
      checkedIndex.value = index;
      // nextTick(() => {
      //   console.log(cateUlRef.value?.offsetLeft);
      //   console.log(cateRef.value);
      //   // transWidth.value =
      //   //   (cateRef.value ? cateRef.value.offsetLeft : 0) -
      //   //   (cateUlRef.value ? cateUlRef.value.offsetLeft : 0) -
      //   //   60;
      //   // console.log(transWidth.value);
      // });
      getModel();
    };

    var isThtottle = ref(true);
    // 分类滑动事件监听
    const touchmove = () => {
      ctx.emit("touchBgmShow", true);
      if (!isThtottle.value) return;
      isThtottle.value = false;
      setTimeout(() => {
        ctx.emit("touchBgmShow", false);
        isThtottle.value = true;
      }, 800);
    };

    /**
     * 案例模型模块
     */

    const state: stateFace = reactive({
      // 案例列表
      caseList: [],
      // 加载状态
      loading: false,
      // 没有更多了
      finished: false,
      page: 1,
      totalPage: 0,
      category: 0,
      // 是否拼接模型列表
      isConcat: false,
    });

    // 获取模型列表
    const getModel = async () => {
      let condition = {
        rows: 10,
        page: state.page,
        category: state.category,
      };
      const res = await getModelList(condition);

      if (
        res.data.code == 200 &&
        res.data.result.list &&
        res.data.result.list.length > 0
      ) {
        if (state.isConcat) {
          state.caseList = state.caseList.concat(res.data.result.list);
        } else {
          state.caseList = res.data.result.list;
        }
        // 总页数
        state.totalPage = Math.ceil(res.data.result.total / 10);
        // 加载状态结束
        state.loading = false;
        // 页数大于等于总页数则加载完成
        if (state.page >= state.totalPage) {
          state.finished = true;
        }
      } else {
        state.caseList = [];
        if (state.page >= state.totalPage) {
          state.finished = true;
        }
      }
    };

    // 上拉加载更多
    const onLoad = () => {
      // 异步更新数据
      state.page++;
      state.isConcat = true;
      getModel();
    };

    // 前往案例页
    const toCase = (val: string) => {
      window.open(val);
    };

    return {
      search,
      cateList,
      cateRefList,
      cateUlRef,
      transWidth,
      ...toRefs(state),
      checkedIndex,
      cateCheck,
      onLoad,
      touchmove,
      toCase,
    };
  },
});
</script>
<style lang="scss" scoped>
.case {
  padding-top: 42px;
  padding-bottom: 50px;
  position: relative;

  &_tabs {
    position: fixed;
    top: 46px;
    width: 100%;
    padding: 0 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: 10px;
    margin-bottom: -10px;
    clip-path: inset(0 0 10px 0);
    background: #fff;
    z-index: 9;
    transition: all 0.5s;

    li {
      display: inline-block;
      width: 60px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #f6f7fa;
      border-radius: 12px 16px 16px 2px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin: 10px;
      transition: all 0.3s;
      cursor: pointer;
    }

    li:last-child {
      margin-right: 60px;
    }

    .active {
      background: linear-gradient(80deg, #30c0fc, #1e7ff6);
      box-shadow: 2px 2px 5px 0px rgba(26, 135, 180, 0.2);
      font-size: 15px;
      color: #fff;
      font-weight: bold;
      transition: all 0.3s;
    }
  }

  &_content {
    margin-top: 20px;
    padding: 0 10px;

    &_item {
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
      padding: 10px;
      margin-bottom: 10px;

      > img {
        width: 100%;
        height: 190px;
      }

      > p {
        margin: 20px 0 10px 0;

        > span:first-of-type {
          font-size: 15px;
          font-weight: bold;
          color: #333333;
          display: inherit;
          width: calc(100% - 60px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > span:last-of-type {
          font-size: 13px;
          font-weight: 400;
          color: #999999;
          display: inherit;
          width: 50px;
          justify-content: flex-end;

          > i {
            margin-top: 2px;
          }
        }
      }
    }
  }

  &_loading {
    margin-top: 10px;
    text-align: center;
  }
}
</style>
